import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import './resume.styles.scss';

const Resume = () => {

    return (
        <SimpleBar className="about-container">
            <p>Resume</p>
        </SimpleBar>
    )
}

export default Resume;

