import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import './stats.styles.scss';

const Stats = () => {

    return (
        <SimpleBar className="about-container">
            <p>Stats</p>
        </SimpleBar>
    )
}

export default Stats;

